import ConnectToServer from "../API/ConnectToServer";

//Action type
// const SET_USER = 'AUTH/SET_USER';


//Started props
let initialState = {
    openFormNewAccountant: false,
    openFormNewClient: false,
    openFormNewFNO: false,
    authUser: {
        id: 0,
    },
    backdrop: false,

};

//Reducers functions SET_LOGOUT
const oilTrackReducer = (state = initialState, action) => {

    switch (action.type) {
        // case SET_USER:
        //     return {
        //         ...state,
        //         authUser: action.user
        //     }
        


        default:
            return state;
    }
}

//Action Creators functions

const setUser = (data) => {
    return { type: 'AUTH/SET_USER', user: data }
}

// //Thunk functions

export const newCompanyRequest = () => {
    return async (dispatch) => {
        const data = await ConnectToServer.setNewCompany()
        if (data) {            
            console.log(data);
        }
    }
}

export const newGasSTRequest = () => {
    return async (dispatch) => {
        const data = await ConnectToServer.setNewGasSt({
            "requestGuid": "b9F9fdDF-123-qwer-33333-a493Ed7cF6Fd",
            "applicantId": 264924632537000000,
            "name": "АЗС Тест",
            "objectType": "PetrolStation"
          }          
          )
        if (data) {            
            console.log(data);
        }
    }
}

export default oilTrackReducer;