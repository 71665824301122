import { Container, Grid } from '@mui/material';
import React, { useState } from 'react';
import TabPage from '../Components/TabPage';

const HomePage = () => {

   

    return <Container maxWidth="100%" sx={{ mt: 8, p: 0 }}>
        <Grid container  >
            <Grid item sx={{ height: '100%' }} >
                <TabPage />
            </Grid>
        </Grid>        
    </Container>
}

export default HomePage;
