import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from '@mui/lab';
import GasStationsTable from '../Pages/GasStationsTable';

export default function TabPage() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);        
    };

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} centered sx={{
                '& .MuiTabs-indicator': {
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                },

                '& .MuiTabs-indicatorSpan': {
                    width: 0,
                    maxWidth: 0,
                    backgroundColor: '#808080',
                },
            }} >
                <Tab label="АЗС/Резервуары" sx={{
                    '&.Mui-selected': {
                        borderLeft: 1,
                        borderRight: 1,
                        borderEndEndRadius: 10,
                        borderEndStartRadius: 10,
                        borderBottom: 1,
                        borderColor: '#adff2f',
                        mt: 0,
                        mb: 0,
                        minHeight: '30px',
                        padding: '0 16px',
                        color: '#808080'
                    },
                    mt: 0,
                    mb: 0,
                    minHeight: '30px',
                    padding: '0 16px'
                }} />
                <Tab label="Поставки" sx={{
                    '&.Mui-selected': {
                        borderLeft: 1,
                        borderRight: 1,
                        borderEndEndRadius: 10,
                        borderEndStartRadius: 10,
                        borderBottom: 1,
                        borderColor: '#adff2f',
                        mt: 0,
                        mb: 0,
                        minHeight: '30px',
                        padding: '0 16px',
                        color: '#808080'
                    },
                    mt: 0,
                    mb: 0,
                    minHeight: '30px',
                    padding: '0 16px'
                }} />
                <Tab label="Замеры" sx={{
                    '&.Mui-selected': {
                        borderLeft: 1,
                        borderRight: 1,
                        borderEndEndRadius: 10,
                        borderEndStartRadius: 10,
                        borderBottom: 1,
                        borderColor: '#adff2f',
                        mt: 0,
                        mb: 0,
                        minHeight: '30px',
                        padding: '0 16px',
                        color: '#808080'
                    },
                    mt: 0,
                    mb: 0,
                    minHeight: '30px',
                    padding: '0 16px'
                }} />
            </Tabs>
                <GasStationsTable />
            
        </Box>
    );
}