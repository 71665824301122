import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import logo2 from '../Img/logo-horizontal.png';
import { Button, Divider, InputBase, MenuList, Stack } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import { useNavigate } from 'react-router-dom';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { styled, alpha } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';

function AppBarSite({ newCompanyRequest }) {

    const [anchorElOnlineBuh, setAnchorElOnlineBuh] = React.useState(null);
    const openOnlineBuh = Boolean(anchorElOnlineBuh);
    const handleClickOnlineBuh = (event) => {
        setAnchorElOnlineBuh(event.currentTarget);
    };
    const handleCloseOnlineBuh = () => {
        setAnchorElOnlineBuh(null);
    };

    const navigate = useNavigate();

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const open = Boolean(anchorElUser);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseExitMenu = () => {
        setAnchorElUser(null);
    };

    const sentData = () => {
        
        let data = JSON.stringify({
            "bin": "110011220024",
            "name": "ИП TEST2",
            "requestGuid": "e8F691BD-D287-51fc-0EAd-b9DbDA9Ed0b3"
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://demo-sunp-api.qoldau.kz/Demo/CreateApplicant',
            headers: { 
              'Content-Type': 'application/json', 
              'Accept': 'text/plain'
            },
            data : data
          };
          
          async function makeRequest() {
            try {
              const response = await axios.request(config);
              console.log(JSON.stringify(response.data));
            }
            catch (error) {
              console.log(error);
            }
          }
          
          makeRequest();

    }


    const settings = [
        { title: 'Главная', onClick: () => { navigate('/'); handleCloseExitMenu() }, idMenu: 'none', idBTN: 'home' },
        { title: 'Аутсорсинг бухгалтерии', onClick: () => { navigate('/uslugi_buhgaltera'); handleCloseExitMenu() }, idMenu: 'none', idBTN: 'buh' },
        { title: 'Онлайн Бухгалтер', onClick: handleClickOnlineBuh, idMenu: "menu-onlineBuh", idBTN: 'OnlineBuh' },
        { title: 'Цены', onClick: () => { window.open('https://finup.kz/buh/price/') }, idMenu: 'none', idBTN: 'price' },
        { title: 'Контакты', onClick: () => { window.open('https://finup.kz/buh/about_us/') }, idMenu: 'none', idBTN: 'contact' },
        // { title: 'Платежи', onClick: handleOpenFormPayments }, 
        //{ title: 'Выход', onClick: () => {localStorage.clear(); window.location.reload(true); } }
    ]

    const onlineBuhMNU = [
        { title: 'Обзор сервиса', onClick: () => { navigate('/online_buhgalteriya'); handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Первые настройки', onClick: () => { navigate('/instrukciy_online_buhgalter'); handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Функции', onClick: () => { navigate('/functions_online_buhgalter'); handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Основные разделы', onClick: () => { navigate('/sections_online_buhgalter'); handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Подпись и печать', onClick: () => { handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Частые вопросы', onClick: () => { handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Поддержка', onClick: () => { handleCloseExitMenu(); handleCloseOnlineBuh(); } },
    ]

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            border: '1px solid',
            borderRadius: 5,
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    }));

    return (
        <AppBar position="fixed" sx={{ backgroundColor: 'white' }} top="0">
            <Container maxWidth="100%">
                <Toolbar disableGutters>
                    <IconButton variant="outlined" onClick={handleOpenUserMenu} sx={{ border: 1, borderRadius: 2, m: 1, p: 0.5, backgroundColor: 'gray' }}>
                        <AppsRoundedIcon fontSize="1.1em" sx={{ color: 'greenyellow' }} />
                    </IconButton>
                    <a href='/' style={{ textDecoration: 'none' }} >
                        <Stack alignItems="center" spacing={1}>
                            <img src={logo2} width={'111px'} alt='Онлайн Бухгалтер | Аутсорсинг Бухгалтерии FinUp.kz' />
                        </Stack>
                    </a>
                    <IconButton onClick={sentData} variant="outlined" sx={{ border: 1, m: 1, p: 0.5, backgroundColor: 'gray', ml: 2 }}>
                        <AddRoundedIcon fontSize="1.1em" sx={{ color: 'greenyellow' }} />
                    </IconButton>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Поиск..."
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="show 4 new mails" >
                            <Badge badgeContent={4} sx={{
                                "& .MuiBadge-badge": {
                                    backgroundColor: "#adff2f"
                                }
                            }} >
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"

                        >
                            <Badge badgeContent={17} sx={{
                                "& .MuiBadge-badge": {
                                    backgroundColor: "#adff2f"
                                }
                            }} >
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            sx={{ fontSize: '2.2em' }}
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={0}
                            aria-haspopup="true"
                            onClick={() => { }}

                        >
                            <AccountCircle fontSize="2em" />
                        </IconButton>
                    </Box>
                    {/* <Box sx={{ flexGrow: 1, display: 'block', textAlign: "right", width: 0 }}>
                        <Tooltip title="Личный кабинет">
                            <IconButton sx={{ p: 0 }} onClick={() => window.open('https://cabinet.finup.kz/', '_blank')} >
                                <AccountBoxRoundedIcon fontSize="large" color='secondary' />
                            </IconButton>
                        </Tooltip>
                    </Box> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default AppBarSite;