import axios from 'axios';

const instance = axios.create({
    baseURL: "https://demo-sunp-api.qoldau.kz",
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/plain',
        'Host': 'https://demo-sunp-api.qoldau.kz/Demo/CreateApplicant',
    }

});

const notInternet = (error) => {
    window.location.href = '/error'
    console.log("нет инета");
    console.log(error)
}

const ConnectToServer = {

    setNewCompany(obj) {
        console.log('test');
        // const axios = require('axios');
        let data = JSON.stringify({
            "bin": "110011220025",
            "name": "ИП TEST5",
            "requestGuid": "e8F691BD-D287-51fc-0EAd-b9DbDA9Ed0b3"
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://demo-sunp-api.qoldau.kz/Demo/CreateApplicant',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log('done');
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log('done');
                console.log(error);
            });
        //         console.log('test');

        //         return instance.post('/Demo/CreateApplicant', JSON.stringify({
        //             "bin": "110011220025",
        //   "name": "ИП TEST5",
        //   "requestGuid": "e8F691BD-D287-51fc-0EAd-b9DbDA9Ed0b3"
        //           }))
        //             .then(response => response.data)
        //             .catch(error => console.log(error))
    },

    setNewGasSt(obj) {
        return instance.post('/Demo/CreateObject', obj)
            .then(response => response.data)
            .catch(error => console.log(error))
    },

}

export default ConnectToServer;