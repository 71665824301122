import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const initialRows = [
    {
        id: 1,
        nameGasStation: 'АЗС №1',
        tankNumber: 1,
        obiem: 1525,
        uroven: 1244,
    },
    {
        id: 2,
        nameGasStation: 'АЗС №1',
        tankNumber: 2,
        obiem: 1335,
        uroven: 1134,
    },
    {
        id: 3,
        nameGasStation: 'АЗС №2',
        tankNumber: 1,
        obiem: 1725,
        uroven: 544,
    },
    {
        id: 4,
        nameGasStation: 'АЗС №2',
        tankNumber: 2,
        obiem: 1385,
        uroven: 1844,
    },
    {
        id: 5,
        nameGasStation: 'АЗС №3',
        tankNumber: 1,
        obiem: 1525,
        uroven: 1244,
    },
    {
        id: 6,
        nameGasStation: 'АЗС №3',
        tankNumber: 2,
        obiem: 1785,
        uroven: 1544,
    },
]

function GasStationsTable() {
    const [rows, setRows] = React.useState(initialRows);
    const columns = [
        {
            field: 'id',
            headerName: '№',
            width: 60,
            editable: false
        },
        {
            field: 'nameGasStation',
            headerName: 'АЗС',
            width: 80,            
            editable: false,
        },
        {
            field: 'tankNumber',
            headerName: '№ резервуара',
            width: 180,
            editable: false,
        },
        {
            field: 'obiem',
            headerName: 'Объем',
            width: 220,
            editable: false,
        },
        {
            field: 'uroven',
            headerName: 'Уровень',
            width: 220,
            editable: false,
        },
    ];

    return (
        <Box sx={{ height: 400, width: 1 }}>
            <DataGrid
                rows={rows}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}

export default GasStationsTable;