//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import AppBarSite from './AppBarSite';
import { newCompanyRequest } from '../Redux/oilTrackReducer';

class AppBarSiteContainer extends React.PureComponent {

    render() {
        return (<AppBarSite                    
            newCompanyRequest={this.props.newCompanyRequest}
                />)
    }
}

const mapStateToProps = (state) => {
    return {    
    }
};

export default connect(mapStateToProps, {       
    newCompanyRequest
})(AppBarSiteContainer)
